import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContextType = {
  showSidebar: boolean;
  setShowSidebar: (value: any) => any;
};

const init = {
  showSidebar: false,
  setShowSidebar: (val:boolean) => null,
};

export const SidebarContext = createContext<LoadingContextType>(init);

export const SidebarContextProvider = (props: { children: ReactNode }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <SidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);