import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContextType = {
  showTechnicalRequest: boolean;
  setShowTechnicalRequest: (value: any) => any;
};

const init = {
  showTechnicalRequest: false,
  setShowTechnicalRequest: () => null,
};

export const TechnicalRequest = createContext<LoadingContextType>(init);

export const TechnicalRequestContextProvider = (props: {
  children: ReactNode;
}) => {
  const [showTechnicalRequest, setShowTechnicalRequest] = useState<boolean>(false);
  return (
    <TechnicalRequest.Provider
      value={{
        showTechnicalRequest,
        setShowTechnicalRequest
      }}
    >
      {props.children}
    </TechnicalRequest.Provider>
  );
};

export const useTechnicalRequest = () => useContext(TechnicalRequest);
