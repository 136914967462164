import { API_URL } from "@/config";
import { createPromiseCallback } from "@/helpers/helperFunctions";
import axios from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type LoadingContextType = {
  updateUserWalkthroughStatus: (value: any) => any;
  getUserWalkthroughStatus: (value: any) => any;
  newUserSteps: number;
  setNewUserSteps: (value: any) => any;
  loading: boolean;
  setLoading: (value: any) => any;
};

const init = {
  updateUserWalkthroughStatus: () => null,
  getUserWalkthroughStatus: () => null,
  newUserSteps: 0,
  setNewUserSteps: () => null,
  newUserOpen: false,
  setNewUserOpen: () => null,
  loading: false,
  setLoading: () => null,
};

export const BacktestTourContext = createContext<LoadingContextType>(init);

export const BacktestTourContextProvider = (props: { children: ReactNode }) => {
  const [newUserSteps, setNewUserSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);

  const getUserWalkthroughStatus = (u: any) => {
    var url = new URL("get_user_walkthrough_status", API_URL);
    url.searchParams.append("user_id", u?.user_id);
    axios
      .get(url.toString(), {
        headers: {
          Authorization: `Bearer ${u?.token}`,
        },
      })
      .then((res: any) => {
        if (res.statusText == "OK") {
          let walkthrough_status = +res?.data?.walkthrough_status || 0;
          //   walkthrough_status = ++walkthrough_status;
          setNewUserSteps(walkthrough_status);
        }
      })
      .catch((e) => {
        console.error("error ", e.message);
        // toastMessage(false, e?.response?.data?.detail);
      });
  };

  const updateUserWalkthroughStatus = async (
    step: number,
    closeModal: boolean = false
  ) => {
    const user = JSON.parse(localStorage.getItem("user-info")!);
    setUserInfo(user);
    const cb = createPromiseCallback();
    if (closeModal) {
      setLoading(false);
    } else setLoading(true);
    var url = new URL("set_user_walkthrough_status", API_URL);
    url.searchParams.append("user_id", user?.user_id as string);
    url.searchParams.append("walkthrough_stage", step.toString());
    axios
      .post(
        url.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      )
      .then((res) => {
        cb(null, res);
        getUserWalkthroughStatus(user)
      })
      .catch((e) => {
        console.error("error ", e.message);
        cb(e);
        throw new Error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
    return cb.promise;
  };
  // @ts-ignore
  // if(typeof window !== 'undefined') window.updateUserWalkthroughStatus = updateUserWalkthroughStatus;
  //   updateUserWalkthroughStatus(5).then(res=>{

  //   })
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user-info")!);
    setUserInfo(user);
    getUserWalkthroughStatus(user);
  }, []);

  return (
    <BacktestTourContext.Provider
      value={{
        updateUserWalkthroughStatus,
        getUserWalkthroughStatus,
        newUserSteps,
        setNewUserSteps,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </BacktestTourContext.Provider>
  );
};

export const useBacktestTour = () => useContext(BacktestTourContext);
