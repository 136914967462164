import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContextType = {
  openAIModal: boolean;
  setOpenAIModal: (value: any) => any;
};

const init = {
  openAIModal: false,
  setOpenAIModal: () => null,
};

export const AIAssistantContext = createContext<LoadingContextType>(init);

export const AIAssistantContextProvider = (props: { children: ReactNode }) => {
  const [openAIModal, setOpenAIModal] = useState(false);
  return (
    <AIAssistantContext.Provider value={{ openAIModal, setOpenAIModal }}>
      {props.children}
    </AIAssistantContext.Provider>
  );
};

export const useAIAssistant = () => useContext(AIAssistantContext);
