import { toast } from "@/components/ui/use-toast";
import { API_URL } from "@/config";
import axios from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
type SubscriptionLevel = "Free" | "Pro" | null;
type SubscriptionContextType = {
  subscriptionData: any;
  setSubscriptionData: (value: any) => any;
  metaData: string | null;
  subscriptionLoading: boolean;
  setMetaData: (value: any) => any;
  getUserType: (value: any) => any;
  setSubscriptionLoading: (value: any) => any;
  userType: SubscriptionLevel;
};

const init: SubscriptionContextType = {
  subscriptionData: null,
  setSubscriptionData: () => null,
  metaData: null,
  subscriptionLoading: true,
  setMetaData: () => null,
  setSubscriptionLoading: () => null,
  getUserType: () => null,
  userType: null,
};

export const SubscriptionContext = createContext<SubscriptionContextType>(init);

export const SubscriptionContextProvider = (props: { children: ReactNode }) => {
  const [subscriptionData, setSubscriptionData] = useState<any>(null);
  const [metaData, setMetaData] = useState<any>(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(true);
  const [userType, setUserType] = useState<SubscriptionLevel>(null);
  const { t } = useTranslation("common");
  const getUserType = (u: any) => {
    var url = new URL("check_subscription_status", API_URL);
    axios
      .get(url.toString(), {
        headers: {
          Authorization: `Bearer ${u?.token}`,
        },
      })
      .then((res) => {
        if (res.statusText == "OK") {
          
          setTimeout(() => {
            const plan = localStorage.getItem(u?.user_id);
            if (plan === "Free" && res?.data === "Pro") {
              toast({
                title: t("Upgraded to the Pro plan successfully"),
              });
            } else if (plan === "Pro" && res?.data === "Free") {
              toast({
                title: t("Downgraded to the Free plan successfully"),
              });
            }
          }, 1000);
          setUserType(res?.data);
          localStorage.setItem(u?.user_id, res?.data);
          setSubscriptionLoading(false);
        }
      })
      .catch((e) => {
        console.error("error ", e.message);
      })
      .finally(() => setSubscriptionLoading(false));
  };
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user-info")!);
    try{
      user && user.subscription != "" && setSubscriptionData(JSON.parse(user.subscription));
    } catch(err) {
      console.log("Error usbcription")
    }
    // user && setSubscriptionData(JSON.parse(user.subscription));
    user && setMetaData(user?.user?.meta_data);
    user && getUserType(user);
  }, []);
  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionData,
        setSubscriptionData,
        metaData,
        setMetaData,
        userType,
        getUserType,
        subscriptionLoading,
        setSubscriptionLoading,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);
