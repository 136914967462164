import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";

type DarkThemeContextType = {
  darkTheme: boolean;
  setDarkTheme: (value: boolean) => any;
};

const init = {
  darkTheme: false,
  setDarkTheme: () => null,
};

export const DarkThemeContext = createContext<DarkThemeContextType>(init);

export const DarkThemeContextProvider = (props: { children: ReactNode }) => {
  const lsRef = useRef(false);
  const [darkTheme, setDarkTheme] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const isDark = JSON.parse(localStorage.getItem("darkTheme") || "false");
      setDarkTheme(isDark);
      lsRef.current = true
    }
  }, []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (darkTheme) {
        window.document.body.classList.add("dark");
      }
      else
        window.document.body.classList.remove("dark");
      if (lsRef.current)
        localStorage.setItem("darkTheme", "" + darkTheme)
    }
  }, [darkTheme]);
  return (
    <DarkThemeContext.Provider value={{ darkTheme, setDarkTheme }}>
      {props.children}
    </DarkThemeContext.Provider>
  );
};

export const useDarkTheme = () => useContext(DarkThemeContext);
