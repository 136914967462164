// import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
const InitializeGoogleAnalytics = () => {
  // Initialize UA
  //   ReactGA.initialize("UA-XXXXXXXX-X");
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize("G-NRPKERR6KT");

  console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (
  action: string,
  category: string,
  event_name: string
) => {
  ReactGA4.event(event_name, {
    category: category,
    action: action,
    event_name: event_name,
  });

  console.log("GA event:", event_name, ":", category, ":", action);
};

export default InitializeGoogleAnalytics;
export { TrackGoogleAnalyticsEvent };
