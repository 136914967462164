import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContextType = {
  showConnectForm: boolean;
  setShowConnectForm: (value: any) => any;
};

const init = {
  showConnectForm: false,
  setShowConnectForm: () => null,
};

export const ConnectFormContext = createContext<LoadingContextType>(init);

export const ConnectFormContextProvider = (props: { children: ReactNode }) => {
  const [showConnectForm, setShowConnectForm] = useState(false);
  return (
    <ConnectFormContext.Provider
      value={{ showConnectForm, setShowConnectForm }}
    >
      {props.children}
    </ConnectFormContext.Provider>
  );
};

export const useConnectForm = () => useContext(ConnectFormContext);
